<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>{{ translationText.text.reOrder }}</li>
    </ul>
    <div class="single-deta-box order-listing-table" data-aos="fade-up">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <template v-for="(order, index) in orders">
              <div class="single-order">
                <div class="order-color order-nom">
                  Order# {{ order.order_number }}
                </div>
                <template v-for="(orderProduct, index) in order.products">
                  <div class="manage-table">
                    <span class="ml-2">{{ orderProduct.name }}</span>
                    <span class="ml-2">${{ orderProduct.price }}</span>

                    <span class="ml-2"
                      ><img :src="orderProduct.image_url"
                    /></span>
                  </div>
                </template>
                <a
                  class="btn btn-primary btn-block mb-4"
                  href="javascript:void(0)"
                  @click="reOrder(order.products)"
                  >{{ translationText.text.buyNow }}</a
                >
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";
import { useRouter } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

const cartData = useCartStore();
const router = useRouter();
const userLanguages = userLanguage();
const userLogin = useLoginStore();
const { languageCode } = storeToRefs(userLanguages);
const orders = ref([]);
const productsList = ref([]);
const openOrders = ref([]);
const cancelOrders = ref([]);
const autoShipOrders = ref([]);
const refundOrders = ref([]);
const props = defineProps(["baseURL"]);
const treeConfig = ref({ nodeWidth: 120, nodeHeight: 80, levelHeight: 200 });
const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};
const rangeCheck = (rangeData, quantity) => {
  let checkFlag = false;
  if (rangeData.length == 2) {
    if (
      quantity >= parseInt(rangeData[0]) &&
      quantity <= parseInt(rangeData[1])
    ) {
      checkFlag = true;
    }
  } else if (rangeData.length == 1) {
    if (quantity > parseInt(rangeData[0])) {
      checkFlag = true;
    }
  } else {
    console.log("jjjjjjjjjjjjjj");
  }

  return checkFlag;
};
const reOrder = (product) => {
  console.log("product ", product);
  cartData.resetAllData();
  // Product Listing

  product.forEach((element) => {
    userLanguages.setLoading(true);
    let productSingle = "";
    // Product Details
    const languageUrl = userLanguages.getLanguageCode
      ? "/" + userLanguages.getLanguageCode
      : "";
    const reqUserType = userLogin.checkUserType
      ? "/" + userLogin.checkUserType
      : "/" + "customer";
    const apiUrl = props.baseURL + "product";
    console.log(
      "product details api",
      apiUrl + "/" + element.id + reqUserType + languageUrl
    );
    axios
      .get(apiUrl + "/" + element.id + reqUserType + languageUrl)
      .then((response) => {
        //this.products = response.data;
        console.log(response.data.products.length);
        if (response.data.products.length > 0) {
          productSingle = response.data.products[0];
          console.log("productSingle 1111111111 ", productSingle);

          // Product add to cart
          let productPrice = 0;
          console.log("productSingle.qty_range_1 ", productSingle.qty_range_1);
          let range1 = productSingle.qty_range_1.replace("+", "").split("-");
          let range2 = productSingle.qty_range_2.replace("+", "").split("-");
          let range3 = productSingle.qty_range_3.replace("+", "").split("-");

          // Customer Range
          let customerRange1 = productSingle.cust_qty_range_1
            .replace("+", "")
            .split("-");
          let customerRange2 = productSingle.cust_qty_range_2
            .replace("+", "")
            .split("-");
          let customerRange3 = productSingle.cust_qty_range_3
            .replace("+", "")
            .split("-");
          // Customer Range Ends Here

          let totalQuantity = 0;
          if (cartData.getCartItems.length > 0) {
            cartData.getCartItems.forEach(function (item) {
              if (item.id == productSingle.id) {
                totalQuantity = item.quantity;
              }
            });
          }
          totalQuantity = 1;
          // rangeCheck(range1,totalQuantity);
          // rangeCheck(range2,totalQuantity);
          // rangeCheck(range3,totalQuantity);

          console.log("check range1 ", rangeCheck(range1, totalQuantity));
          console.log("check range2 ", rangeCheck(range2, totalQuantity));
          console.log("check range3 ", rangeCheck(range3, totalQuantity));

          let rangeWhich1 = rangeCheck(range1, totalQuantity);
          let rangeWhich2 = rangeCheck(range2, totalQuantity);
          let rangeWhich3 = rangeCheck(range3, totalQuantity);

          let customerRangeWhich1 = rangeCheck(customerRange1, totalQuantity);
          let customerRangeWhich2 = rangeCheck(customerRange2, totalQuantity);
          let customerRangeWhich3 = rangeCheck(customerRange3, totalQuantity);

          if (range1) console.log("totalQuantity ", totalQuantity);

          if (userLogin.checkIsLoggedIn) {
            if (userLogin.getUserUserData.user_type == "ambassador") {
              if (element.autoship == "no") {
                productPrice = productSingle.amb_sale_price;
              } else if (element.autoship == "yes") {
                productPrice = productSingle.autoship_price_amb;
              } else {
                productPrice = productSingle.amb_sale_price;
              }

              if (element.autoship == "no") {
                if (rangeWhich1) {
                  if (productSingle.qty_range_1_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice * productSingle.qty_range_1_per) / 100;
                  }
                } else if (rangeWhich2) {
                  if (productSingle.qty_range_2_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice * productSingle.qty_range_2_per) / 100;
                  }
                } else if (rangeWhich3) {
                  if (productSingle.qty_range_3_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice * productSingle.qty_range_3_per) / 100;
                  }
                }
              }
            } else if (userLogin.getUserUserData.user_type == "scholar") {
              if (element.autoship == "no") {
                productPrice = productSingle.sc_sale_price;
              } else if (element.autoship == "yes") {
                productPrice = productSingle.autoship_price_sc;
              } else {
                productPrice = productSingle.sc_sale_price;
              }
              if (element.autoship == "no") {
                if (rangeWhich1) {
                  if (productSingle.qty_range_1_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice * productSingle.qty_range_1_per) / 100;
                  }
                } else if (rangeWhich2) {
                  if (productSingle.qty_range_2_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice * productSingle.qty_range_2_per) / 100;
                  }
                } else if (rangeWhich3) {
                  if (productSingle.qty_range_3_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice * productSingle.qty_range_3_per) / 100;
                  }
                }
              }
            } else {
              if (element.autoship == "no") {
                productPrice = productSingle.sale_price;
              } else if (element.autoship == "yes") {
                productPrice = productSingle.autoship_price_customer;
              } else {
                productPrice = productSingle.autoship_price_customer;
              }
              if (element.autoship == "no") {
                if (customerRangeWhich1) {
                  if (productSingle.cust_qty_range_1_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice * productSingle.cust_qty_range_1_per) / 100;
                  }
                } else if (customerRangeWhich2) {
                  if (productSingle.cust_qty_range_2_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice * productSingle.cust_qty_range_2_per) / 100;
                  }
                } else if (customerRangeWhich3) {
                  if (productSingle.cust_qty_range_3_per > 0) {
                    productPrice =
                      productPrice -
                      (productPrice * productSingle.cust_qty_range_3_per) / 100;
                  }
                }
              }
            }

            //let productImage = "";
            // if (productSingle.value.images_url.length > 0) {
            //   productImage = productSingle.value.images_url[0];
            // }
          } else {
            if (element.autoship == "no") {
              productPrice = productSingle.sale_price;
            } else if (element.autoship == "yes") {
              productPrice = productSingle.autoship_price_customer;
            } else {
              productPrice = productSingle.sale_price;
            }
            if (element.autoship == "no") {
            if (customerRangeWhich1) {
              if (productSingle.cust_qty_range_1_per > 0) {
                productPrice =
                  productPrice -
                  (productPrice * productSingle.cust_qty_range_1_per) / 100;
              }
            } else if (customerRangeWhich2) {
              if (productSingle.cust_qty_range_2_per > 0) {
                productPrice =
                  productPrice -
                  (productPrice * productSingle.cust_qty_range_2_per) / 100;
              }
            } else if (customerRangeWhich2) {
              if (productSingle.cust_qty_range_3_per > 0) {
                productPrice =
                  productPrice -
                  (productPrice * productSingle.cust_qty_range_3_per) / 100;
              }
            }
            }
          }

          let productImage = "";
          if (productSingle.images_url.length > 0) {
            productImage = productSingle.images_url[0];
          }

          console.log("price ", productPrice);
          const cartItem = {
            id: productSingle.id,
            name: productSingle.name,
            price: productPrice,
            description: productSingle.short_description,
            image: productImage,
            hoverImage: productSingle.thumbnail_url,
            quantity: 1,
            purchaseType: element.autoship == "no" ? 1 : 2,
          };
          //console.log("cartItem : ",cartItem);
          // console.log("purchaseType : ",purchaseType.value);
          // console.log("quantity : ",quantity.value);
          // console.log("cartItem : ",cartItem);
          userLanguages.setLoading(false);
          cartData.addAllProducts(productSingle);
          cartData.addToCart(cartItem);
          // Product add to cart ends here
        }
        //console.log("product data",this.products.products.length);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // Product Listing ends Here
    router.push({ name: "Checkout" });
    // Product Detais end here
  });

  // if (product.products.length > 0) {
  //   let allProduct = [];

  //   product.products.array.forEach((element) => {
  //     allProduct.push({
  //       id: element.id,
  //       price: element.price,
  //       quantity: element.quantity,
  //       purchaseType: null,
  //     });
  //   });
  // }

  // axios
  //   .get(
  //     "https://cymcorp-admin.demomyprojects.com/api/v1/user/order/cancel/" +
  //       orderId,
  //     {
  //       headers,
  //     }
  //   )
  //   .then((response) => {
  //     console.log("Cancel Order ", response.data.message);
  //     swal({
  //       position: "top-end",
  //       icon: "success",
  //       title: response.data.message,
  //       button: false,
  //       timer: 2500,
  //     });
  //   })
  //   .catch((error) => {
  //     console.error("Error fetching data:", error);
  //   });
};

const getGenealogy = () => {
  //const apiUrl = props.baseURL + "translateany";
  userLanguages.setLoading(true);
  axios
    .get(props.baseURL + "user/orders", {
      headers,
    })
    .then((response) => {
      console.log("My Team Order Listing ", response.data.data);
      userLanguages.setLoading(false);
      orders.value = response.data.data;
    })
    .catch((error) => {
      userLanguages.setLoading(false);
      console.error("Error fetching data:", error);
    });
};


const translationTextEn = ref({
  text: {
    reOrder: "Re Order",
    buyNow: "Buy Now",
  },
  language: "",
});

const translationText = ref({
  text: {
    reOrder: "Re Order",
    buyNow: "Buy Now",
  },
  language: "",
});
const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  
  if(userLanguages.getLanguageCode != 'en'){

    const apiUrl = props.baseURL + "translateany";
    axios
      .post(apiUrl, translationText.value)
      .then((response) => {
        console.log("response trnasate :", response.data);
        translationText.value = response.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }
  else{
    translationText.value = translationTextEn.value;
  }
  
};

watch(languageCode, () => {
  console.log("ref sidebar language change");
  translatText();
});

onMounted(() => {
  translatText();
  getGenealogy();
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}
</style>
