<template>
  <div class="banner inner-banner">
    <img
      src="/assets/images/testimonial-banner.jpg"
      alt=""
      class="w-100 zoom-out"
    />
    <div class="banner-text" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-8 col-sm-8 col-xs-8">
            <h1>{{ translationText.text.successStory }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="testimonial-area">
    <div class="container">
      <div
        class="row justify-content-center aos-init aos-animate"
        data-aos="fade-up"
      >
        <div class="title col-lg-8 text-center">
          <h2>{{ translationText.text.successStory }}</h2>
          <p>{{ translationText.text.successStorySubText }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs" id="myTab">
            <li class="nav-item">
              <a href="#complex" class="nav-link active" data-bs-toggle="tab">{{
                translationText.text.successStory
              }}</a>
            </li>
            <li class="nav-item">
              <a href="#prezerv" class="nav-link" data-bs-toggle="tab"
                >PREZERV</a
              >
            </li>
            <li class="nav-item">
              <a href="#viprox" class="nav-link" data-bs-toggle="tab">VIPROX</a>
            </li>
            <li class="nav-item">
              <a href="#complex2" class="nav-link" data-bs-toggle="tab"
                >GSH COMPLEX</a
              >
            </li>
            <li class="nav-item">
              <a href="#unevie" class="nav-link" data-bs-toggle="tab"
                >UNE-VIE</a
              >
            </li>
            <li class="nav-item">
              <a href="#triozyme" class="nav-link" data-bs-toggle="tab"
                >TRIOZYME</a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" id="complex">
              <div class="complex-video">
                <Splide
                  aria-labelledby="video-example-heading"
                  :options="options"
                  :extensions="extensions"
                >
                  <SplideSlide
                    v-for="(id, index) in videos"
                    :key="id"
                    :data-splide-youtube="id"
                  >
                    <img
                      :src="`https://i3.ytimg.com/vi/${id}/maxresdefault.jpg`"
                      :alt="`YouTube Sample ${index + 1}`"
                    />
                  </SplideSlide>
                  <SplideSlide
                    v-for="(slide, index) in sliderSuccess"
                    :key="index"
                  >
                    <div class="testimonial-contents">
                      <span class="quote-image"
                        ><img src="/assets/images/quote1.png"
                      /></span>
                      <div v-html="slide"></div>
                      <h4>Cathy Tait</h4>
                    </div>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
            <div class="tab-pane fade" id="prezerv">
              <Splide
                aria-labelledby="video-example-heading"
                :options="options"
                :extensions="extensions"
              >
                <SplideSlide
                  v-for="(slide, index) in sliderPrezerv"
                  :key="index"
                >
                  <div class="testimonial-contents">
                    <span class="quote-image"
                      ><img src="/assets/images/quote1.png"
                    /></span>
                    <div v-html="slide"></div>
                    <h4>NANCY G.</h4>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
            <div class="tab-pane fade" id="viprox">
              <Splide
                aria-labelledby="video-example-heading"
                :options="options"
                :extensions="extensions"
              >
                <SplideSlide
                  v-for="(slide, index) in sliderViprox"
                  :key="index"
                >
                  <div class="testimonial-contents">
                    <span class="quote-image"
                      ><img src="/assets/images/quote1.png"
                    /></span>
                    <div v-html="slide"></div>
                    <h4>Thomas Carranco</h4>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
            <div class="tab-pane fade" id="complex2">
              <Splide
                aria-labelledby="video-example-heading"
                :options="options"
                :extensions="extensions"
              >
                <SplideSlide
                  v-for="(slide, index) in sliderGshComplex"
                  :key="index"
                >
                  <div class="testimonial-contents">
                    <span class="quote-image"
                      ><img src="/assets/images/quote1.png"
                    /></span>
                    <div v-html="slide"></div>
                    <h4>Elaine Jerrils</h4>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
            <div class="tab-pane fade" id="unevie">
              <Splide
                aria-labelledby="video-example-heading"
                :options="options"
                :extensions="extensions"
              >
                <SplideSlide
                  v-for="(slide, index) in sliderUneVie"
                  :key="index"
                >
                  <div class="testimonial-contents">
                    <span class="quote-image"
                      ><img src="/assets/images/quote1.png"
                    /></span>
                    <div v-html="slide"></div>
                    <h4>Joseph Orilia</h4>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
            <div class="tab-pane fade" id="triozyme">
              <Splide
                aria-labelledby="video-example-heading"
                :options="options"
                :extensions="extensions"
              >
                <SplideSlide
                  v-for="(slide, index) in sliderTriozyme"
                  :key="index"
                >
                  <div class="testimonial-contents">
                    <span class="quote-image"
                      ><img src="/assets/images/quote1.png"
                    /></span>
                    <div v-html="slide"></div>
                    <h4>Carolyn T.</h4>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ProductListQuiz></ProductListQuiz>
</template>

<script setup>
import { defineComponent, ref, onMounted, watch, defineProps } from "vue";
import { mapState, storeToRefs } from "pinia";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import $ from "jquery";
import ProductListQuiz from "../components/Product/ProductListQuiz";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import { Video } from "@splidejs/splide-extension-video";
import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";
import { userLanguage } from "../stores/language";
const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
const props = defineProps(["baseURL"]);
const videos = ref(["8B4fJZRkeOc", "YZ__M7qvPDI", "tsiCFTVemnA"]);
const options = {
  rewind: true,
  //destroy: true,
  type: "loop",
  perPage: 1,
  pagination: false,
  gap: "2em",
  breakpoints: {
    1199: {
      perPage: 1,
    },
    767: {
      perPage: 1,
    },
  },
  //heightRatio: 0.5625,
};

const sliderSuccess = ref([]);
const sliderPrezerv = ref([]);
const sliderViprox = ref([]);
const sliderGshComplex = ref([]);
const sliderUneVie = ref([]);
const sliderTriozyme = ref([]);

const translationText = ref({
  text: {
    successStory: "Success Stories",
    successStorySubText:
      "Learn what others had to say after using our line of products.",
    slider1:
      " So I wanted to share something about our GSH complex and univie and the power of these products topically... I burned my knuckle last night on a sheet pan taking it out of the oven and got a blister that hurt pretty good. I have always grabbed a bowl or glass of ice water to help with the pain of burns... Sometimes it can be pretty bad. But, since I was trying to serve dinner, I decided to grab a little bowl and mix a solution of GSH and Univie and spread it on my knuckle. Within a few minutes the pain lessoned. It was still there but not as bad. After dinner I put another layer on my hand and went to bed. There was still a little pain but certainy bearable. When I awoke this morning the blister was half the size it was last night and the redness was gone. During the day today the blister oozed a little liquid and shrunk. The area if very dry from the mixture. I should have applied the mixture to some cream and applied it that way this morning... But.... the blister tonight is gone and no pain!!! WOW!! I have always had a hard time dealing with burn pain.. I have always used ICE!!! <br /> I am so thankful Robert... Amazing!!!",
    slider2:
      '<p>I tried the Preserv derma and am a person who is allergic to a lot of products. Let me also say that I am 76 years old. I was hesitant to try it and put it on my knees for a few days to be sure that it was ok. During the Pandemic I saw that my make-up was not doing good things for my face and I had several dark spots. It got better once I quit using the makeup. But now that I was going to be able to get out again, I decided to try the Preserv derma.</p><p>It has been so amazing and has taken my dry skin spots away while also helping with my "older" face and wrinkles! It is a wonderful product.</p><p>It has also taken away my forehead wrinkles, tightened my lines around my eyes and mouth and made an amazing difference in my jaw line. Now I am noticing that it is helping with my drooping eyelids and taking the droop away! The wonderful bonus is that the lavender smell when you use it is so soothing.</p><p> Thanks to the Preserv derma, I am ready to go back out into the world without make-up!</p><p>And at my age that is remarkable and so wonderful!</p><p>Thank you – Marisol</p>',
    slider3:
      "My name is Tom Carranco I am 77 years old and I was on Viprox and in Oct. of 2018 I went into the hospital for a small procedure. I was in recovery and the nurses rammed my feet and lower legs two times with a gurney that had someone on it. I was hurt pretty bad and ended up in rehab for just over a month. And to this day I am recovered as a was taking Viprox before and thisis why my recovery was faster then it would have been, thanks be to God.Now I am still taking Viprox and I went to see my doctor in January 2019 and he took me all of My high Blood Pressure pills I was taking and about four other different pills .praise be to God!",
    slider4:
      "Greetings!!When I tried the GSH eye wash in my eye that was being doctored for possible infections & allergies- the eye cleared up overnight!!! So I am wondering if the same could be put in the ears. About 18 months ago I got major hearing loss and tinnitus at the same time a few weeks after a bad fall.Hope you can help!!I've been healthy and no longer any gut issue since beginning Viprox 2 years ago, Thanks!",
    slider5:
      "<p>My solution for a severe case of Psoriasis.</p><p>My name is Joseph, I have a 2 year old nephew that suffered from a severe case of Psoriasis (head to toe). His mother would give him his prescription as described, but it just wouldn't work. She then asked me about Une Vie, and if that could help.</p><p>We decided we had nothing to lose giving it a try and included Une-Vie in his bath the same day. You should of seen the dead skin just washing away, it was impressive! Also, the redness and itchiness went away, my sister is sold to this product for life.</p><p>CymCorp, a big THANK YOU from our whole family!</p>",
    slider6:
      "<p>I had chronic heartburns during my pregnancy.</p><p>Two years ago I was pregnant with my first son. About six months in my pregnancy, I started to have terrible heartburns which would make me lose a lot of sleep – it was getting problematic for both, me and my son's health.As many know, while being pregnant, you cannot take any prescription drugs or pain killers. I went to my doctor with my issue and he suggested a natural solution which would be harmless to both of us. I started taking Triozyme then and it worked instantly! Even to this day, my family put the prescription drugs and pain killers to rest and we only use Triozyme for any digestion issues.</p>",
  },
  language: "",
});

const translatText = () => {
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if (userLanguages.getLanguageCode != "en") {
    const apiUrl = props.baseURL + "translateany";
    console.log("translationText.value", translationText.value);
    axios
      .post(apiUrl, translationText.value)
      .then((response) => {
        console.log("response trnasate Testimonials :", response.data);
        translationText.value = response.data;

        sliderSuccess.value.push(response.data.text.slider1);
        sliderPrezerv.value.push(response.data.text.slider2);
        sliderViprox.value.push(response.data.text.slider3);
        sliderGshComplex.value.push(response.data.text.slider4);
        sliderUneVie.value.push(response.data.text.slider5);
        sliderTriozyme.value.push(response.data.text.slider6);

        console.log("sliderPrezerv", sliderPrezerv);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  } else {
    sliderSuccess.value.push(translationText.value.text.slider1);
    sliderPrezerv.value.push(translationText.value.text.slider2);
    sliderViprox.value.push(translationText.value.text.slider3);
    sliderGshComplex.value.push(translationText.value.text.slider4);
    sliderUneVie.value.push(translationText.value.text.slider5);
    sliderTriozyme.value.push(translationText.value.text.slider6);
  }
};
watch(languageCode, () => {
  location.reload();
  //translatText();
});
onMounted(() => {
  translatText();
});
</script>
