import { createApp } from "vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router/index.js";

import swal from "sweetalert";
import "bootstrap/dist/css/bootstrap.min.css";

import * as bootstrap from "bootstrap";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import "vue3-carousel/dist/carousel.css";
import "aos/dist/aos.css";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import "remixicon/fonts/remixicon.css";
import "@splidejs/splide/dist/css/splide.min.css";
import "vue-select/dist/vue-select.css";
import "../src/assets/css/style.css";
import "../src/assets/css/style.scss";
import "../src/assets/css/responsive.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
//import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

//import { createManager } from '@vue-youtube/core';

window.axios = require("axios"); //using axios as a global object
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
// const app = createApp(App)
// app.use(router)
// app.use(pinia)

// app.mount('#app');

createApp(App)
  .use(CKEditor)
  .use(createPinia())
  .use(pinia)
  .use(router)
  .mount("#app");
