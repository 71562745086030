<template>
  <!-- Welcome-modal -->
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog origin-pop">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <span class="pop-logo"
            ><a href=""><img src="/assets/images/logo.png" /></a
          ></span>
          <h5 class="modal-title" id="exampleModalLabel">
            My Country of Origin
          </h5>
          <div class="row">
            <div class="col">
              <a
                @click="addCountry('United States')"
                class="origin-box"
                href="#"
                style="background: url(/assets/images/united.png)"
              >
                <div class="flag-box">
                  <img src="/assets/images/united-flag.png" class="mb-3" />
                  <h5>United States</h5>
                </div>
              </a>
            </div>
            <div class="col">
              <a
                @click="addCountry('Canada')"
                class="origin-box"
                href="#"
                style="background: url(/assets/images/canada.png)"
              >
                <div class="flag-box">
                  <img src="/assets/images/canada-flag.png" class="mb-3" />
                  <h5>Canada</h5>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Welcome-modal -->
</template>
<script setup>
import { ref, computed, defineProps, onMounted, onUpdated } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { useLoginStore } from "../stores/login";
import { useCartStore } from "../stores/cart";
import $ from "jquery";
const cartData = useCartStore();
//const cartItemData = ref([])
const router = useRouter();
const userLogin = useLoginStore();
const quantity = ref(1);
const coupon_code = ref();
const isCoupnApply = ref(false);

const addCountry = async (country) => {
  console.log("country", country);
  userLogin.addUserCountry(country);
  window.$("#exampleModal").modal("hide");
};
onMounted(() => {
  //   $(document).ready(function () {
  //     window.$("#exampleModal").modal("show");
  //   });
  //console.log("exampleModal");
  // window.$("#exampleModal").modal("show");
  console.log("exampleModal on mounted ");
});
onUpdated(() => {
  console.log("update coynty popup");
});
</script>
