<template>
  <section class="login-sec">
    <div class="container">
      <!--    Logo Div-->
      <div class="row">
        <div class="col-12 text-center title">
          <h2>{{ translationText.text.login }}</h2>
          <!-- <router-link :to="{ name: 'Home' }">
          <img id="logo" src="../assets/images/logo.png" />
        </router-link> -->
        </div>
      </div>

      <div class="row">
        <div class="col-12 justify-content-center d-flex flex-row">
          <div id="signin-div" class="flex-item border">
            <!-- <h2 class="pt-4 pl-4">Login</h2> -->
            <Form novalidate @submit="signin" class="pt-4 pl-4 pr-4">
              <div class="form-group">
                <label>{{ translationText.text.email }}</label>
                <Field
                  type="email"
                  class="form-control"
                  name="email"
                  v-model="email"
                  rules="email|required"
                />
                <ErrorMessage name="email" />
              </div>
              <div class="form-group">
                <label>{{ translationText.text.password }}</label>
                <Field
                  type="password"
                  class="form-control"
                  name="password"
                  rules="required"
                  v-model="password"
                />
                <ErrorMessage name="password" />
              </div>
              <!-- <small class="form-text text-muted"
              >By continuing, you agree to Simplecoding's Conditions of Use and
              Privacy Notice.</small
            > -->
              <button type="submit" class="btn btn-primary mt-2">
                {{ translationText.text.signIn }} {{ useLoginStore.isLoggedIn }}
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </Form>
            <router-link
              :to="{
                name: 'ForgetPassword',
                params: { sluguser: userLogin.getUserUrlName },
              }"
              class="lost-password text-center mx-auto px-5 py-1 mb-2"
              >Lost your password?</router-link
            >

            <!-- <hr />
          <small class="form-text text-muted pt-2 pl-4 text-center"
            >New to Simplecoding?</small
          > -->
            <!-- <p class="text-center">
            <router-link
              :to="{ name: 'Signup' }"
              class="lost-password text-center mx-auto px-5 py-1 mb-2"
              >Lost your password?</router-link
            >
          </p> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <ProductListQuiz></ProductListQuiz>
</template>

<script setup>
import { ref, computed, defineProps, onMounted, watch } from "vue";
import { Form, Field, ErrorMessage, defineRule, configure } from "vee-validate";
import * as AllRules from "@vee-validate/rules";
import { mapState, storeToRefs } from "pinia";
import { useLoginStore } from "../stores/login";
import { userLanguage } from "../stores/language";
import { useRouter } from "vue-router";
import ProductListQuiz from "../components/Product/ProductListQuiz";
const router = useRouter();
const userLogin = useLoginStore();
const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
let loading = ref(0);
const password = ref("");
const email = ref("");
const props = defineProps(["baseURL"]);
console.log("prpos ", props.baseURL);

Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

const validateEmail = (value) => {
  // if the field is empty
  if (!value) {
    return "This field is required";
  }
  // if the field is not a valid email
  // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  // if (!regex.test(value)) {
  //   return 'This field must be a valid email';
  // }
  // All is good
  return true;
};

const validatePassword = (value) => {
  // if the field is empty
  if (!value) {
    return "This field is required";
  }

  // All is good
  return true;
};

const signin = async () => {
  //console.log(values, null, 2);
  //e.preventDefault();
  //loading = true;
  userLanguages.setLoading(true);
  const user = {
    email: email.value,
    password: password.value,
  };

  await axios
    //.post(`${props.baseURL}auth/login`, user)
    .post(props.baseURL + "login", user)
    .then((res) => {
      console.log("response ", res.data);
      userLogin.userLoggedIn(res.data);
      //localStorage.setItem("token", res.data.token);
      //this.$emit("fetchData");
      userLanguages.setLoading(false);
      if (userLogin.getUserUrlName != null) {
        router.push({
          name: "DashboardUser",
          params: { sluguser: userLogin.getUserUrlName },
        });
      } else {
        router.push({
          name: "Dashboard",
          //params: { sluguser: userLogin.getUserUrlName },
        });
      }
    })
    .catch((err) => {
      //console.log("errerr ", err.response.data.message);
      //console.log("errerr ", res);
      userLanguages.setLoading(false);
      swal({
        position: "top-end",
        icon: "error",
        title: err.response.data.message,
        button: false,
        timer: 2500,
      });
      console.log(err);
    })
    .finally(() => {
      userLanguages.setLoading(false);
      //loading = false;
    });
};

const translationTextEn = ref({
  text: {
    login: "Login",
    signIn: "Sign In ",
    email: "Email",
    password: "Password",
  },
  language: "",
});

const translationText = ref({
  text: {
    login: "Login",
    signIn: "Sign In ",
    email: "Email",
    password: "Password",
  },
  language: "",
});

const translatText = () => {
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if(userLanguages.getLanguageCode != 'en'){

    const apiUrl = props.baseURL + "translateany";
    axios
      .post(apiUrl, translationText.value)
      .then((response) => {
        console.log("response trnasate :", response.data);
        translationText.value = response.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }
  else{
    translationText.value = translationTextEn.value;
  }
  
};
watch(languageCode, () => {
  translatText();
});

onMounted(() => {
  translatText();
});
</script>

<style scoped>
.btn-dark {
  background-color: #e7e9ec;
  color: #000;
  font-size: smaller;
  border-radius: 0;
  border-color: #adb1b8 #a2a6ac #a2a6ac;
}

.btn-primary {
  background-color: #f0c14b;
  color: black;
  border-color: #a88734 #9c7e31 #846a29;
  border-radius: 0;
}

#logo {
  width: 150px;
}

@media only screen and (min-width: 992px) {
  #signin-div {
    width: 400px;
  }
}
</style>
