<template>
  <!-- <Navbar
    :cartCount="cartCount"
    @resetCartCount="resetCartCount"
    v-if="!['Signup', 'Signin'].includes($route.name)"
  /> -->
  <Navbar
    :cartCount="cartCount"
    @resetCartCount="resetCartCount"
    :baseURL="baseURL"
    :translationText="translationText"
  />
  <div style="min-height: 60vh">
    <loader
      v-if="userLanguages.loading"
      name="spinning"
      loadingText=""
      textColor="#ffffff"
      textSize="20"
      textWeight="500"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      :disableScrolling="true"
    ></loader>
    <router-view
      :baseURL="baseURL"
      :products="products"
      :categories="categories"
      @fetchData="fetchData"
    ></router-view>

    <teleport to="head">
      <title>{{ title }}</title>
      <meta name="description" :content="description" />
    </teleport>

    <ProductAddToCartModal
      ref="modal"
      :baseURL="baseURL"
      :translationText="translationText"
    ></ProductAddToCartModal>
    <CountryPopup></CountryPopup>
  </div>
  <!-- <Footer v-if="!['Signup', 'Signin'].includes($route.name)" /> -->
  <Footer :baseURL="baseURL" :translationText="translationText"></Footer>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import AOS from "aos";
import Splitting from "splitting";
import ProductAddToCartModal from "./components/ProductDetails/ProductAddToCartModal";
import CountryPopup from "./views/CountryPopup";
import { userLanguage } from "./stores/language";
import { useRoute } from "vue-router";
import { useLoading } from "vue3-loading-overlay";
// Import stylesheet
const title = ref(
  "Natural Supplements | Supplier of Natural Life Products | Cymcorp"
);
const description = ref(
  "We provide all-natural and science-based products that have been clinically proven to improve your overall well-being, we strive to help people live healthier and happier lives."
);

const route = useRoute();
console.log("route app ", route.name);
// metaService.update("title", title);
// metaService.update("description", description);
import loader from "vue3-ui-preloader";

// Import the CSS or use your own!
import "vue3-ui-preloader/dist/loader.css";

//const loader = ref(useLoading());

// Loading
const fullPage = ref(true);
const formContainer = ref(null);
// End Loading
const baseURL = ref(process.env.VUE_APP_APP_API_URL);
const userLanguages = userLanguage();
const cartCount = ref(0);
const getTranslatText = async () => {};

const { languageCode } = storeToRefs(userLanguages);
const { loading } = storeToRefs(userLanguages);

const fetchData = async () => {
  // fetch products
  await axios
    .get(this.baseURL + "product/")
    .then((res) => (this.products = res.data))
    .catch((err) => console.log(err));

  //fetch categories
  await axios
    .get(this.baseURL + "category/")
    .then((res) => (this.categories = res.data))
    .catch((err) => console.log(err));

  //fetch cart items
  if (this.token) {
    await axios.get(baseURL + `cart/?token=${this.token}`).then(
      (response) => {
        if (response.status == 200) {
          // update cart
          this.cartCount = Object.keys(response.data.cartItems).length;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
};
const resetCartCount = async () => {
  cartCount = 0;
};

const translationTextEn = ref({
  text: {
    joinTeam: "Join Our Team",
    shopHere: "Shop Now",
    login: "Login",
    newsLetter: "Subscribe To Our Newsletter",
    newsLetterSubText:
      "Be the first to know about new collections and exclusive offers.",
    agree: "I agree to receiving marketing emails and special deals",
    emailAddress: "Email Address",
    aboutus: "About Us",
    faq: "FAQ",
    testimonials: "Testimonials",
    usefulLinks: "Useful Links",
    legal: "Legal",
    products: "Products",
    backToTop: "Back to Top",
    allRights: "All rights reserved. Call us Tollfree",
    checkout: "Checkout",
    addMoreProducts: "Add More Products",
    apply: "Apply",
    cart: "Cart",
    applyCode: "Enter Coupon Code",
    applied: "Applied",
    oneTime: "One-Time Purchase",
    everyMonth: "Every month/Smart buy",
  },
  language: "",
});

const translationText = ref({
  text: {
    joinTeam: "Join Our Team",
    shopHere: "Shop here",
    login: "Login",
    newsLetter: "Subscribe To Our Newsletter",
    newsLetterSubText:
      "Be the first to know about new collections and exclusive offers.",
    agree: "I agree to receiving marketing emails and special deals",
    emailAddress: "Email Address",
    aboutus: "About Us",
    faq: "FAQ",
    testimonials: "Testimonials",
    usefulLinks: "Useful Links",
    legal: "Legal",
    products: "Products",
    backToTop: "Back to Top",
    allRights: "All rights reserved. Call us Tollfree",
    checkout: "Checkout",
    addMoreProducts: "Add More Products",
    apply: "Apply",
    cart: "Cart",
    applyCode: "Enter Coupon Code",
    applied: "Applied",
    oneTime: "One-Time Purchase",
    everyMonth: "Every month/Smart buy",
  },
  language: "",
});

const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
    if(userLanguages.getLanguageCode != 'en'){

      const apiUrl = baseURL.value + "translateany";
      axios
        .post(apiUrl, translationText.value)
        .then((response) => {
          console.log("response trnasate :", response.data);
          translationText.value = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

    }
    else{
      translationText.value = translationTextEn.value;
    }
  
};

watch(route, (to, from) => {
  console.log("app router change slug", to.name);
  if (to.name == "Product" || to.name == "ProductUser") {
    title.value =
      "Health Product Supplier | Check Our Product Catalog| Cymcorp";
    description.value =
      "Cymcorp is the supplier of true quality of natural life products. Check our product catalog to shop from our range of products.";
  } else if (to.params.slug == "reference-materials") {
    title.value =
      "Reference Materials | Supplier of Natural Life Products | Cymcorp";
    description.value =
      " we strive to help people live healthier and happier lives. Check our reference materials.";
  } else if (to.params.slug == "about-us") {
    title.value = "cymcorp Health Products | All Natural Supplement | About us";
    description.value =
      "We offer natural health products and we can help you by guiding you to a Good Solid Health. Check our vision for the next 5 years.";
  } else if (to.name == "ShowDetails" && to.params.id == 1) {
    title.value = "GSH COMPLEX | Supplements for Immune System | Cymcorp";
    description.value =
      "Our natural supplement GSH Complex is a key component to restoring, strengthening and balancing your immune system so that it can effectively protect your health and fend off illness.";
  } else if (to.name == "ShowDetails" && to.params.id == 2) {
    title.value = "Prezerv Gold | Best Cream for Mature Skin | Cymcorp";
    description.value =
      "Prezerv Gold is an all-natural, rejuvenating skincare treatment. Prezerv Gold package is composed of 1 Viprox package and 1 Prezerv Derma day and night cream.";
  } else if (to.name == "ShowDetails" && to.params.id == 5) {
    title.value = "PREZERV Derma | Skin Repairing Cream| Cymcorp";
    description.value =
      "Prezerv derma is our day-and-night cream, made from GSH Complex and UNE VIE. They work in unison to revitalize your skin and reduce the signs of aging, leaving you with smoother, younger more radiant skin.";
  } else if (to.name == "ShowDetails" && to.params.id == 6) {
    title.value = "Triozyme Chewables | Natural Digestive Enzymes| Cymcorp";
    description.value =
      'Triozyme is a dietary enzymatic supplement that aids digestive function and provides your body with essential nutrients in perfectly balanced proportions, making it a "super food" that can enhance your overall digestive health.';
  } else if (to.name == "ShowDetails" && to.params.id == 7) {
    title.value =
      "Prezerv Bronze | All-natural Skin Care for Aging Skin | Cymcorp";
    description.value =
      "Prezerv Bronze is an all-natural, rejuvenating skincare treatment that transforms the way you care for your body.";
  } else if (to.name == "ShowDetails" && to.params.id == 8) {
    title.value = "UNE VIE | Natural Hydrating Product | Cymcorp";
    description.value =
      "UNE VIE is structured water produced through a unique and extensive purification process. It acts as a highly efficient “delivery system” with powerful hydrating and restorative properties that transports vital nutrients to every cell in your body. ";
  } else if (to.name == "ShowDetails" && to.params.id == 9) {
    title.value = "Prezerv Silver | Best Cream for Mature Skin | Cymcorp";
    description.value =
      "Prezerv Silver is an all-natural, rejuvenating skincare treatment that transforms the way you care for your body. Unlike conventional skincare and cosmetic products, Prezerv Silver does not cover up blemishes — it repairs them.";
  } else if (to.name == "ShowDetails" && to.params.id == 10) {
    title.value = "Viprox | Best Supplement for Immune System | Cymcorp";
    description.value =
      "Viprox,a non-toxic, non-invasive clinically proven therapy for the treatment of a host immune system and host immune related conditions.";
  } else if (to.name == "ShowDetails" && to.params.id == 11) {
    title.value = "Viprox Junior | Best Immune Supplement| Cymcorp";
    description.value =
      "Each component in our VIPROX therapy serves a very specific purpose in maintaining your health. This results in extra energy, improved mental clarity and overall well-being.";
  } else {
    title.value =
      "Natural Supplements | Supplier of Natural Life Products | Cymcorp";
    description.value =
      "We provide all-natural and science-based products that have been clinically proven to improve your overall well-being, we strive to help people live healthier and happier lives.";
  }
});

watch(languageCode, () => {
  console.log("isLoggedIn ref changed, do something!");
  translatText();
});

// watch(loading, (newValue, oldValue) => {
//   //let loader = useLoading();
//   if (newValue == true) {
//     loader.value.show({
//       // Optional parameters
//       container: fullPage.value ? null : formContainer.value,
//       canCancel: true,
//       //onCancel: onCancel,
//     });
//     // setTimeout(() => {
//     //   loader.hide();
//     // }, 5000);
//   } else {
//     loader.value.hide();
//   }
// });

onMounted(() => {
  // loader.show({
  //   // Optional parameters
  //   container: fullPage.value ? null : formContainer.value,
  //   canCancel: true,
  //   //onCancel: onCancel,
  // });

  translatText();
  Splitting();
  AOS.init({
    duration: 800,
    once: true,
  });
});
</script>

<style>
html {
  overflow-y: scroll;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid;
  border-color: #000 #0000;
  animation: l1 1s infinite;
}
@keyframes l1 {
  to {
    transform: rotate(0.5turn);
  }
}
</style>
