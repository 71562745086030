<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>Orders Details</li>
    </ul>
    <div class="single-deta-box" data-aos="fade-up">
      <div class="d-flex justify-content-center" style="display: none">
        <div class="card card-1">
          <div class="card-body">
            <div class="row justify-content-between mb-3">
              <!-- <div class="col-auto">
                <h6 class="color-1 mb-0 change-color">Order</h6>
              </div> -->
              <div class="col-auto">
                <small>Order : {{ order.order_number }}</small>
              </div>
            </div>

            <template v-for="(product, index) in order.products">
              <div class="row">
                <div class="col">
                  <div class="card card-2">
                    <div class="card-body">
                      <div class="media">
                        <div class="sq align-self-center">
                          <img
                            class="img-fluid my-auto align-self-center mr-2 mr-md-4 pl-0 p-0 m-0 user-pro-img"
                            :src="product.thumbnail_url"
                            width="135"
                            height="135"
                          />
                        </div>
                        <div class="media-body my-auto text-right">
                          <div class="row my-auto flex-column flex-md-row">
                            <div class="col my-auto">
                              <h6 class="mb-0">{{product.name}}</h6>
                            </div>
                            <div class="col my-auto">
                              <small>Qty : {{ product.pivot.quantity }}</small>
                            </div>
                            <div class="col my-auto">
                              <h6 class="mb-0">${{ product.pivot.price }}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div class="row mt-4">
              <div class="col">
                <div class="row justify-content-between">
                  <div class="col-auto">
                    <h5 class="mb-3 text-dark">Order Details</h5>
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="flex-sm-col text-right col">
                    <p class="mb-1"><b>Total</b></p>
                  </div>
                  <div class="flex-sm-col col-auto">
                    <p class="mb-1">${{ order.total }}</p>
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="flex-sm-col text-right col">
                    <p class="mb-1"><b>Discount</b></p>
                  </div>
                  <div class="flex-sm-col col-auto">
                    <p class="mb-1">${{ order.discount_amount }}</p>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="flex-sm-col text-right col">
                    <p class="mb-1"><b>Delivery Options</b></p>
                  </div>
                  <div class="flex-sm-col col-auto">
                    <p class="mb-1">{{ order.shipping_method }}</p>
                    <p class="mb-1">${{ order.shipping_amount }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row invoice">
              <div class="col">
                <p class="mb-1">Order Date : {{ order.order_date }}, Autoship: {{ order.autoship }}</p>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="jumbotron-fluid">
              <div class="row justify-content-between">
                <div class="col-sm-auto col-auto my-auto"></div>
                <div class="col-auto my-auto">
                  <h2 class="mb-0 font-weight-bold">TOTAL PAID</h2>
                </div>
                <div class="col-auto my-auto ml-auto">
                  <h1 class="display-3">${{ order.total }}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted } from "vue";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRouter, useRoute } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

const router = useRoute();
console.log("params ", router.params);
const userLanguages = userLanguage();
const userLogin = useLoginStore();
const props = defineProps(["baseURL"]);
const order = ref({ order_number: "", total: "" });

const treeConfig = ref({ nodeWidth: 120, nodeHeight: 80, levelHeight: 200 });
const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};
const getGenealogy = () => {
  //const apiUrl = props.baseURL + "translateany";
  axios
    .get(props.baseURL + "user/order/" + router.params.id, {
      headers,
    })
    .then((response) => {
      console.log("Order Listing ", response.data.order);
      order.value = response.data.order;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

onMounted(() => {
  getGenealogy();
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}
</style>
