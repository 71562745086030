<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>{{ translationText.text.myTeamOrder }}</li>
    </ul>
    <div class="single-deta-box table-body-bg" data-aos="fade-up">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-borderless main">
              <thead>
                <!-- <tr class="head">
                      <th scope="col" class="ml-2">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                        </div>
                      </th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col">Customer</th>
                      <th scope="col">Fullfillment</th>
                      <th scope="col">Total</th>
                      <th scope="col">Profit</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated</th>
                    </tr> -->
              </thead>
              <tbody>
                <template v-for="(order, index) in orders">
                  <tr class="rounded bg-white">
                    <td class="order-color">Order# {{ order.order_number }}</td>

                    <td class="d-flex align-items-center">
                      <template v-for="(orderProduct, index) in order.products">
                        <div>
                          <img
                            :src="orderProduct.thumbnail_url"
                            class="rounded-circle"
                            width="25"
                          />
                          <span class="ml-2">{{ orderProduct.name }}</span>
                        </div>
                      </template>
                    </td>
                    <!-- <td>Total ${{ order.total }}</td> -->
                    <td>
                      <ul>
                        <li>
                          <router-link
                            :to="{
                              name: 'OrdersDetails',
                              params: { id: order.order_id },
                            }"
                          >
                            {{ translationText.text.orderDetails }}
                          </router-link>
                        </li>

                        <!-- <li>
                          <a class="dropdown-item" href="#">Print Receipts</a>
                        </li> -->
                        <li>
                          <a
                            class="dropdown-item"
                            href="javascript:void(0)"
                            @click="cancelOrdersFun(order.order_id)"
                          >
                            {{ translationText.text.return }}</a
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRouter } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

const router = useRouter();
const userLanguages = userLanguage();
const userLogin = useLoginStore();

const orders = ref([]);
const { languageCode } = storeToRefs(userLanguages);
const openOrders = ref([]);
const cancelOrders = ref([]);
const autoShipOrders = ref([]);
const refundOrders = ref([]);
const props = defineProps(["baseURL"]);
const treeConfig = ref({ nodeWidth: 120, nodeHeight: 80, levelHeight: 200 });
const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};

const cancelOrdersFun = (orderId) => {
  swal("You won't be able to revert this!", {
    buttons: {
      cancel: "Cancel",
      ok: {
        value: "ok",
      },
    },
  }).then((value) => {
    if (value == "ok") {
      userLanguages.setLoading(true);
      axios
        .get(props.baseURL + "user/order/cancel/" + orderId, {
          headers,
        })
        .then((response) => {
          console.log("Cancel Order ", response.data.message);
          userLanguages.setLoading(false);
          swal({
            position: "top-end",
            icon: "success",
            title: response.data.message,
            button: false,
            timer: 2500,
          });
        })
        .catch((error) => {
          userLanguages.setLoading(false);
          console.error("Error fetching data:", error);
        });
    }
  });
};

const getGenealogy = () => {
  //const apiUrl = props.baseURL + "translateany";
  userLanguages.setLoading(true);
  axios
    .get(props.baseURL + "user/team/orders", {
      headers,
    })
    .then((response) => {
      console.log("My Team Order Listing ", response.data.orders);
      orders.value = response.data.orders;
      userLanguages.setLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      userLanguages.setLoading(false);
    });
};



const translationTextEn = ref({
  text: {
    myTeamOrder: "My Team Order",
    orderDetails: "Order Details",
    return: "Return",
  },
  language: "",
});

const translationText = ref({
  text: {
    myTeamOrder: "My Team Order",
    orderDetails: "Order Details",
    return: "Return",
  },
  language: "",
});
const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if(userLanguages.getLanguageCode != 'en'){

    const apiUrl = props.baseURL + "translateany";
    axios
      .post(apiUrl, translationText.value)
      .then((response) => {
        console.log("response trnasate :", response.data);
        translationText.value = response.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }
  else{
    translationText.value = translationTextEn.value;
  }
  
};

watch(languageCode, () => {
  console.log("ref sidebar language change");
  translatText();
});

onMounted(() => {
  getGenealogy();
  translatText();
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}
</style>
