<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>{{ translationText.text.customerOrderHistory }}</li>
    </ul>
    <div class="single-deta-box order-listing-table" data-aos="fade-up">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <template v-for="(order, index) in orders">
              <div class="single-order">
                <div class="order-color order-nom">
                  Order# {{ order.order_number }}
                </div>
                <template v-for="(orderProduct, index) in order.products">
                  <div class="manage-table">
                    <span class="ml-2">{{ orderProduct.name }}</span>
                    <span class="ml-2">${{ orderProduct.price }}</span>
                    <span class="ml-2">Qty {{ orderProduct.quantity }}</span>
                    <span class="ml-2"
                      ><img :src="orderProduct.thumbnail_url"
                    /></span>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRouter } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

const router = useRouter();
const userLanguages = userLanguage();
const userLogin = useLoginStore();
const { languageCode } = storeToRefs(userLanguages);
const orders = ref([]);

const openOrders = ref([]);
const cancelOrders = ref([]);
const autoShipOrders = ref([]);
const refundOrders = ref([]);

const treeConfig = ref({ nodeWidth: 120, nodeHeight: 80, levelHeight: 200 });
const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};
const props = defineProps(["baseURL"]);
const getGenealogy = () => {
  userLanguages.setLoading(true);
  //const apiUrl = props.baseURL + "translateany";
  axios
    .get(props.baseURL + "user/customer/orders", {
      headers,
    })
    .then((response) => {
      console.log("My Team Order Listing ", response.data.orders);
      orders.value = response.data.orders;
      userLanguages.setLoading(false);
    })
    .catch((error) => {
      userLanguages.setLoading(false);
      console.error("Error fetching data:", error);
    });
};

const translationTextEn = ref({
  text: {
    customerOrderHistory: "Customers Order History",
  },
  language: "",
});

const translationText = ref({
  text: {
    customerOrderHistory: "Customers Order History",
  },
  language: "",
});
const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";

    if(userLanguages.getLanguageCode != 'en'){
      const apiUrl = props.baseURL + "translateany";
      axios
        .post(apiUrl, translationText.value)
        .then((response) => {
          console.log("response trnasate :", response.data);
          translationText.value = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

    }
    else{
       translationText.value = translationTextEn.value;
    }
  
};

watch(languageCode, () => {
  console.log("ref sidebar language change");
  translatText();
});

onMounted(() => {
  getGenealogy();
  translatText();
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}
</style>
