<template>
  <div class="left-menus" data-aos="fade-up">
    <ul class="list-unstyled">
      <li class="">
        <button
          class="collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded="false"
        >
          <span class="icons"><i class="ri-briefcase-line"></i></span>
          {{ translationText.text.profile }}
          <span class="right-arw"
            ><i class="ri-arrow-drop-right-line"></i
          ></span>
        </button>
        <div class="collapse" id="home-collapse" style="">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li>
              <router-link :to="{ name: 'Profile' }" class="link-dark rounded"
                >{{ translationText.text.personalInfo }}</router-link
              >
            </li>
            <!-- <li>
              <a href="#" class="link-dark rounded">Add Debit & Credit Cards</a>
            </li> -->
            <li>
              <router-link
                :to="{ name: 'ManageAddress' }"
                class="link-dark rounded"
                >{{ translationText.text.manageAddress }}</router-link
              >
            </li>
          </ul>
        </div>
      </li>
      <li class="">
        <button
          class="collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#Dashboard-collapse"
          aria-expanded="false"
        >
          <span class="icons"><i class="ri-dashboard-2-line"></i></span>
          {{ translationText.text.orderManagement }}
          <span class="right-arw"
            ><i class="ri-arrow-drop-right-line"></i
          ></span>
        </button>
        <div class="collapse" id="Dashboard-collapse" style="">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li>
              <router-link :to="{ name: 'Orders' }" class="link-dark rounded"
                >{{ translationText.text.orderListing }}</router-link
              >
            </li>
            <!-- <li><a href="#" class="link-dark rounded">Open Order</a></li> -->
            <!-- <li
              v-if="
                userLogin.checkUserType == 'ambassador' ||
                userLogin.checkUserType == 'customer'
              "
            >
              <a href="#" class="link-dark rounded">Buy Again</a>
            </li> -->
            <li v-if="userLogin.checkUserType == 'scholar'">
              <router-link :to="{ name: 'ReOrder' }" class="link-dark rounded"
                >{{ translationText.text.Reorder }}</router-link
              >
            </li>
            <!-- <li
              v-if="
                userLogin.checkUserType == 'ambassador' ||
                userLogin.checkUserType == 'customer'
              "
            >
              <a href="#" class="link-dark rounded">Cancel</a>
            </li>
            <li>
              <a href="#" class="link-dark rounded"
                >AutoShip Status active/inactive</a
              >
            </li> -->
            <li
              v-if="
                userLogin.checkUserType == 'ambassador' ||
                userLogin.checkUserType == 'customer'
              "
            >
              <router-link
                :to="{ name: 'OrderHistory' }"
                class="link-dark rounded"
                >{{ translationText.text.orderHistory }}</router-link
              >
            </li>
            <li>
              <a href="#" @click="openCartPopup" class="link-dark rounded"
                >{{
                translationText.text.viewCart
              }}</a
              >
            </li>
          </ul>
        </div>
      </li>
      <li class="">
        <button
          class="collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#Plan-collapse"
          aria-expanded="false"
        >
          <span class="icons"><i class="ri-calculator-line"></i></span>
          {{ translationText.text.messaging }}
          <span class="right-arw"
            ><i class="ri-arrow-drop-right-line"></i
          ></span>
        </button>
        <div class="collapse" id="Plan-collapse" style="">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <router-link :to="{ name: 'MessageList' }" class="link-dark rounded"
              >{{ translationText.text.inBox }}</router-link
            >

            <!-- <li><a href="#" class="link-dark rounded">In-Box</a></li> -->
            <li>
              <router-link
                :to="{ name: 'MessageCompose' }"
                class="link-dark rounded"
                >{{ translationText.text.compose }}</router-link
              >
              <!-- <a href="#" class="link-dark rounded">Compose</a> -->
            </li>
          </ul>
        </div>
      </li>

      <li
        class=""
        v-if="
          userLogin.checkUserType == 'ambassador' ||
          userLogin.checkUserType == 'scholar'
        "
      >
        <button
          class="collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#Commission-Earnings"
          aria-expanded="false"
        >
          <span class="icons"><i class="ri-calculator-line"></i></span>
          {{ translationText.text.commissionEarnings }}
          <span class="right-arw"
            ><i class="ri-arrow-drop-right-line"></i
          ></span>
        </button>
        <div class="collapse" id="Commission-Earnings" style="">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li>
              <router-link
                :to="{ name: 'Commission' }"
                class="link-dark rounded"
                >{{
                  userLogin.checkUserType == "scholar"
                    ? "Earnings History"
                    : "Earnings History"
                }}</router-link
              >
            </li>
          </ul>
        </div>
      </li>

      <li
        class=""
        v-if="
          userLogin.checkUserType == 'ambassador' ||
          userLogin.checkUserType == 'scholar'
        "
      >
        <button
          class="collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#My-Team"
          aria-expanded="false"
        >
          <span class="icons"><i class="ri-calculator-line"></i></span>
          {{ translationText.text.myTeam }}
          <span class="right-arw"
            ><i class="ri-arrow-drop-right-line"></i
          ></span>
        </button>
        <div class="collapse" id="My-Team" style="">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li>
              <router-link
                :to="{ name: 'UserAmbassador' }"
                class="link-dark rounded"
                >{{ translationText.text.my }} Ambassador</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'UserScholar' }"
                class="link-dark rounded"
                >{{
                  userLogin.checkUserType == "ambassador"
                    ? "My Scholar"
                    : "My Scholar"
                }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'TeamOrders' }"
                class="link-dark rounded"
                >{{ translationText.text.teamOrder }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'DownlineGenealogy' }"
                class="link-dark rounded"
                >{{ translationText.text.downlineGenealogy }}</router-link
              >
            </li>
            <!-- <li>
              <a href="#" class="link-dark rounded">Rank History</a>
            </li> -->
          </ul>
        </div>
      </li>

      <li
        class=""
        v-if="
          userLogin.checkUserType == 'ambassador' ||
          userLogin.checkUserType == 'scholar'
        "
      >
        <button
          class="collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#My-Customers"
          aria-expanded="false"
        >
          <span class="icons"><i class="ri-calculator-line"></i></span>
          {{ translationText.text.myCustomers }}
          <span class="right-arw"
            ><i class="ri-arrow-drop-right-line"></i
          ></span>
        </button>
        <div class="collapse" id="My-Customers" style="">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li>
              <router-link
                :to="{ name: 'UserCustomers' }"
                class="link-dark rounded"
                >{{ translationText.text.customers }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'UserCustomerOrderListing' }"
                class="link-dark rounded"
                >{{ translationText.text.customersOrderHistory }}</router-link
              >
              <!-- <a href="#" class="link-dark rounded">Customers Order History</a> -->
            </li>
          </ul>
        </div>
      </li>

      <!-- <li>
        <a
          @click="$emit('logoutClicked')"
          href="javascript:void(0)"
          class="link-dark rounded"
          ><span class="icons"><i class="ri-calculator-line"></i></span
          >Logout</a
        >
      </li> -->
    </ul>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  defineProps,
  onUpdated,
  onMounted,
  defineEmits,
  watch,
} from "vue";
import { storeToRefs } from "pinia";
import { userLanguage } from "../stores/language";
import { useLoginStore } from "../stores/login";
import { useRouter } from "vue-router";
import $ from "jquery";
const router = useRouter();
const userLanguages = userLanguage();
const userLogin = useLoginStore();
const props = defineProps(["baseURL", "translationText"]);

const { languageCode } = storeToRefs(userLanguages);

const translationTextEn = ref({
  text: {
    profile: "Profile",
    personalInfo: "Personal Information",
    manageAddress: "Manage Addresses",
    orderManagement: "Order Management",
    orderListing: "Order Listing",
    Reorder: "Reorder",
    orderHistory: "Order History",
    viewCart: "View Cart",
    messaging: "Messaging",
    inBox: "In-Box",
    compose: "Compose",
    commissionEarnings: "Commission Earnings",
    myTeam: "My Team",
    my: "My",
    teamOrder: "My Team Order",
    downlineGenealogy: "Downline Genealogy",
    myCustomers: "My Customers",
    customers: "Customers",
    customersOrderHistory: "Customers Order History",
  },
  language: "",
});


const translationText = ref({
  text: {
    profile: "Profile",
    personalInfo: "Personal Information",
    manageAddress: "Manage Addresses",
    orderManagement: "Order Management",
    orderListing: "Order Listing",
    Reorder: "Reorder",
    orderHistory: "Order History",
    viewCart: "View Cart",
    messaging: "Messaging",
    inBox: "In-Box",
    compose: "Compose",
    commissionEarnings: "Commission Earnings",
    myTeam: "My Team",
    my: "My",
    teamOrder: "My Team Order",
    downlineGenealogy: "Downline Genealogy",
    myCustomers: "My Customers",
    customers: "Customers",
    customersOrderHistory: "Customers Order History",
  },
  language: "",
});
const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
    if(userLanguages.getLanguageCode != 'en'){
      const apiUrl = props.baseURL + "translateany";
      axios
        .post(apiUrl, translationText.value)
        .then((response) => {
          console.log("response trnasate :", response.data);
          translationText.value = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

    }
    else{
      translationText.value = translationTextEn.value;
    }
  
};

const openCartPopup = async () => {
  console.log("openCartPopup");
  window.$("#cartModal").modal("show");
  //$("#cartModalLabel").modal("show");
};
watch(languageCode, () => {
  console.log("ref sidebar language change");
  translatText();
});
onMounted(() => {
  translatText();
});
</script>
