<template>
  <div class="banner inner-banner">
    <img :src="pageData.page_banner_url" alt="" class="w-100 zoom-out" />
    <div class="banner-text" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 text-start">
            <h1 v-html="pageData.name"></h1>
            <p v-html="pageData.page_subheading"></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-html="pageData.page_content"></div>

  <section class="team-sec" v-if="slugCms == 'about-us'">
    <div class="container">
      <div class="row justify-content-center">
        <div class="title col-lg-7 text-center" data-aos="fade-up">
          <h2>{{ translationText.text.meetTeam }}</h2>
        </div>
        <div class="col-12" data-aos="fade-up">
          <div class="member-slider">
            <Carousel
              v-bind="settings"
              :wrapAround="true"
              :breakpoints="silderBreakOptions"
            >
              <Slide v-for="(slide, index) in silder" :key="slide">
                <div class="carousel__item">
                  <div class="items">
                    <a href="Javascript:void(0)" @click="modalBio(slide, index)"
                      ><img :src="slide.image" alt=""
                    /></a>

                    <h5>{{ slide.name }}</h5>
                    <h6 v-if="index == 0">
                      {{ translationText.text.description1 }}
                    </h6>
                    <h6 v-if="index == 1">
                      {{ translationText.text.description2 }}
                    </h6>
                    <h6 v-else>{{ translationText.text.description3 }}</h6>
                    <div class="social">
                      <a href="javascript:; ">
                        <i class="ri-mail-fill"></i>
                      </a>
                      <a href="javascript:; ">
                        <i
                          @click="modalBio(slide, index)"
                          class="ri-profile-line"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </Slide>

              <!-- <template #addons>
                    <Navigation />
                    <Pagination />
                </template> -->
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div
    class="modal fade"
    id="myModalBio"
    tabindex="-1"
    aria-labelledby="cartModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog about-pop">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <img :src="bioData.image" />
          <!-- <button type="button" class="close" data-dismiss="modal">
            &times;
          </button> -->
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ri-close-line"></i>
          </button>

          <h4 class="modal-title">{{ bioData.name }}</h4>
        </div>
        <div class="modal-body">
          <p v-html="bioData.description"></p>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-default" data-dismiss="modal">
            Close
          </button> -->
        </div>
      </div>
    </div>
  </div>

  <ProductListQuiz></ProductListQuiz>
</template>

<script setup>
import { defineComponent, ref, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import $ from "jquery";
import ProductListQuiz from "../components/Product/ProductListQuiz";
import { userLanguage } from "../stores/language";
import { useRoute } from "vue-router";

const route = useRoute();

let slugCms = route.params.slug;
const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
const props = defineProps(["baseURL"]);
const bioData = ref({
  name: "",
  description: "",
  image: "",
});
const settings = ref({
  itemsToShow: 1,
  //snapAlign: "center",
});
const silderBreakOptions = ref({
  // 700px and up
  767: {
    itemsToShow: 1,
    //snapAlign: "center",
  },
  // 1024 and up
  1024: {
    itemsToShow: 3,
    //snapAlign: "start",
  },
});
const pageData = ref({
  name: "",
  page_subheading: "",
  page_banner_url: "",
  page_content: "",
});
const silder = ref([
  {
    name: "MARISOL LOPEZ",
    description: "VICE PRESIDENT / ADMIN & FINANCE",
    image: "/assets/images/MARISOL-LOPEZ.png",
  },
  {
    name: "WILLY TSAKU, MD",
    description: "MEDICAL ADVISOR",
    image: "/assets/images/willyt.png",
  },

  {
    name: "ROBERT GAUTHIER",
    description: "CEO, PRESIDENT",
    image: "/assets/images/ROBERT-GAUTHIER.png",
  },
]);

const modalBio = async (sildeData, index) => {
  console.log("moal bio", sildeData);
  console.log("moal bio index", index);

  bioData.value.name = sildeData.name;

  bioData.value.image = sildeData.image;

  bioData.value.description =
    "My journey into health care began in 2000, when I learned the sobering statistic that 15M children were orphans of AIDS in Africa. It was unfathomable. In the age of modern medical, scientific and technological advancements, how is this even possible? I knew the sad truth that developing countries can’t afford the exorbitant costs associated with pharmaceutical drugs and don’t always have adequate access to health care professionals.";
  console.log("bioData update ", bioData.value);
  window.$("#myModalBio").modal("show");
};
const getLanguage = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl = props.baseURL + "page/" + slugCms + languageUrl;

  await axios
    .get(apiUrl)
    //.get(apiUrl)
    .then((res) => {
      console.log("response ", res.data);
      if (res.data.page_details.length > 0) {
        pageData.value = res.data.page_details[0];
      }

      //siteLanguage.value = res.data.languages
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {});
};



const translationTextEn = ref({
  text: {
    meetTeam: "Meet Our Team",
    description1: "VICE PRESIDENT / ADMIN & FINANCE",
    description2: "MEDICAL ADVISOR",
    description3: "CEO, PRESIDENT",
  },
  language: "",
});

const translationText = ref({
  text: {
    meetTeam: "Meet Our Team",
    description1: "VICE PRESIDENT / ADMIN & FINANCE",
    description2: "MEDICAL ADVISOR",
    description3: "CEO, PRESIDENT",
  },
  language: "",
});

const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if(userLanguages.getLanguageCode != 'en'){
    const apiUrl = props.baseURL + "translateany";
    axios
    .post(apiUrl, translationText.value)
    .then((response) => {
      console.log("response trnasate :", response.data);
      translationText.value = response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });

  }else{

    translationText.value = translationTextEn.value;
  }
  
};

watch(languageCode, () => {
  console.log("isLoggedIn ref changed, do something!");
  translatText();
  getLanguage();
});

onMounted(() => {
  translatText();
  getLanguage();

  //const container = document.querySelector('.faq-accordion-title');
  // document
  //   .querySelector(".faq-accordion-title")
  //   .addEventListener("click", (e) => {
  //     alert("ddd");
  //     // container.style.setProperty('--position', `${e.target.value}%`);
  //   });
});

// route.beforeRouteEnter(to, from, next) {
//     console.log(to.params.slug);
// }

watch(route, (to, from) => {
  slugCms = to.params.slug;
  getLanguage();
  console.log("new slug", to.params.slug);
});
</script>
