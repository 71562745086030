import { createPinia, defineStore } from "pinia";
export const useLoginStore = defineStore("login", {
  state: () => ({
    isLoggedIn: false,
    userType: null,
    loginToken: null,
    userCountry: null,
    userData: null,
    userUrlName: null,
    userUrlInfoData: {
      enrolling_sponsor_name: null,
      id: null,
      user_type: null,
    },
    referal_id: null,
  }),
  persist: true,
  getters: {
    checkIsLoggedIn: (state) => state.isLoggedIn,
    loggedToken: (state) => state.loginToken,
    checkUserType: (state) => state.userType,
    getUserCountry: (state) => state.userCountry,
    getUserUrlName: (state) => state.userUrlName,
    getUserUrlInfoData: (state) => state.userUrlInfoData,
    getUserUserData: (state) => state.userData,
    getReferalId: (state) => state.referal_id,
  },
  actions: {
    addReferralId(referalId) {
      this.referal_id = referalId;
    },
    userLoggedIn(userData) {
      this.isLoggedIn = true;
      this.loginToken = userData.access_token;
      if (userData.user) {
        this.userData = userData.user;
      }
      this.userType = userData.user_type;
    },
    resetUserLoggedIn(userData) {
      this.isLoggedIn = false;
      this.userType = null;
      this.loginToken = null;
      this.userData = null;
    },
    addUserCountry(country) {
      this.userCountry = country;
    },
    addUserUrlInfoData(infoData) {
      this.userUrlInfoData.enrolling_sponsor_name =
        infoData.enrolling_sponsor_name;
      this.userUrlInfoData.id = infoData.id;
      this.userUrlInfoData.user_type = infoData.user_type;
      this.referal_id = infoData.id;
    },
    addUserUrlName(urlName) {
      this.userUrlName = urlName;
    },
    restUserUrlName() {
      this.userUrlInfoData = null;
      this.referal_id = null;
    },
  },
});
