<template>
  <footer>
    <div class="back-top">
      <a href="#" class="top-link line-animation"
        >{{ props.translationText.text.backToTop }}
        <i class="ri-arrow-up-line"></i
      ></a>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <router-link to="/" class="f-logo">
            <img src="/assets/images/logo.png" alt="" />
          </router-link>

          <h5>{{ props.translationText.text.newsLetter }}</h5>
          <p>
            {{ props.translationText.text.newsLetterSubText }}
          </p>
          <form>
            <input
              type="email"
              :placeholder="props.translationText.text.emailAddress"
            />
            <input type="submit" id="" value="Submit" />
          </form>
          <div class="col-md-12 pd-5">
            <div class="custome_checkbox">
              <input type="checkbox" id="mark" rows="1" />
              <label for="mark">{{ props.translationText.text.agree }}</label>
            </div>
          </div>
        </div>
        <div class="col-md-2 link">
          <h5>{{ props.translationText.text.usefulLinks }}</h5>
          <ul>
            <li v-if="userLogin.getReferalId != null">
              <router-link
                :to="{
                  name: 'CmsPageUser',
                  params: {
                    sluguser: userLogin.getUserUrlName,
                    slug: 'about-us',
                  },
                }"
                >{{ props.translationText.text.aboutus }}</router-link
              >
            </li>
            <li v-else>
              <router-link
                :to="{ name: 'CmsPage', params: { slug: 'about-us' } }"
                >{{ props.translationText.text.aboutus }}</router-link
              >
            </li>
            <!-- <li>
              <a href="javascript: ;">Blog</a>
            </li> -->
            <li v-if="userLogin.getReferalId != null">
              <router-link :to="'/' + userLogin.getUserUrlName + '/faq'">{{
                props.translationText.text.faq
              }}</router-link>
            </li>
            <li v-else>
              <router-link to="/faq">{{
                props.translationText.text.faq
              }}</router-link>
            </li>
            <li>
              <router-link to="testimonials">{{
                props.translationText.text.testimonials
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-2 link">
          <h5>{{ props.translationText.text.legal }}</h5>
          <ul>
            <template v-for="(footerLegal, index) in siteFooterLegal">
              <li>
                <router-link
                  :to="{
                    name: 'CmsPageUser',
                    params: {
                      sluguser: userLogin.getUserUrlName,
                      slug: footerLegal.slug,
                    },
                  }"
                  >{{ footerLegal.name }}</router-link
                >
              </li>
              <!-- <li v-if="footerLegal.slug == 'about-us'">
                <router-link to="/about">{{ footerLegal.name }}</router-link>
              </li>
              <li v-else>
                <router-link :to="{ name: 'CmsPage', params: { slug: footerLegal.slug } }">{{
                  footerLegal.name
                }}</router-link>
              </li> -->
              <!-- <li>
               <a href="javascript: ;">{{footerLegal.name}}</a>
             </li> -->
            </template>
            <!-- <li>
            <a href="javascript: ;">Terms & Conditions</a>
          </li>
          <li>
            <a href="javascript: ;">Policies and Procedures</a>
          </li>
          <li>
            <a href="javascript: ;">Privacy Policy</a>
          </li>
          <li>
            <a href="javascript: ;">Spam Policy</a>
          </li>
          <li>
            <a href="javascript: ;">Refund (Cancellation) Policy</a>
          </li> -->
          </ul>
        </div>
        <div class="col-md-3 link">
          <div v-html="siteFooterAddress.page_content"></div>
          <!-- <div class="widget">
            <address>
              19381 County road #24 Dunvegan,<br />
              Ontario Canada, K0C 1A0
            </address>
            <div class="call">
              <a href="javascript: ;" title=""
                ><i class="ri-phone-line"></i> 613-527-1234</a
              >
              <a href="javascript: ;" title=""
                ><i class="ri-phone-line"></i> 1-888-968-3592 (Toll Free)</a
              >
            </div>
          </div> -->
          <div class="social">
            <a href="javascript:; ">
              <i class="ri-facebook-circle-fill"></i>
            </a>
            <a href="javascript:; ">
              <i class="ri-instagram-line"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="privacy">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 text-md-end order-md-2 order-1">
            <div class="cards">
              <img src="/assets/images/card1.png" alt="" />
              <img src="/assets/images/card2.png" alt="" />
              <img src="/assets/images/card3.png" alt="" />
              <img src="/assets/images/card4.png" alt="" />
            </div>
          </div>
          <div class="col-md-6 order-md-1 order-2">
            <p>
              © 2024 Cymcorp, {{ props.translationText.text.allRights }} -
              1-888-968-3592
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import {
  ref,
  computed,
  defineProps,
  onUpdated,
  onMounted,
  onActivated,
  watch,
} from "vue";
import { storeToRefs } from "pinia";
import { useLoginStore } from "../stores/login";
import { useCartStore } from "../stores/cart";
import { userLanguage } from "../stores/language";
import { onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import $ from "jquery";
const userLogin = useLoginStore();
const cartData = useCartStore();
const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
const token = ref("");
const props = defineProps(["baseURL", "translationText"]);
const siteFooterLegal = ref([]);
const defaultLanguage = ref("");
const siteFooterAddress = ref("");

const userSiteLanguage = (language) => {
  if (userLanguages.getLanguageCode == language.code) {
    console.log("click language", language);
  } else {
    userLanguages.setLanguage(language);
    defaultLanguage.value = language.code;

    location.reload();
  }
};
const getLanguage = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  await axios
    .get(`${props.baseURL}navigations/footer` + languageUrl)
    //.get(apiUrl)
    .then((res) => {
      console.log("response ", res.data);
      siteFooterLegal.value = res.data.navigations;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      //loading = false;
    });
};

const footerAddress = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  await axios
    .get(`${props.baseURL}page/foooter-address` + languageUrl)
    //.get(apiUrl)
    .then((res) => {
      console.log("response ", res.data);
      if (res.data.page_details.length > 0) {
        siteFooterAddress.value = res.data.page_details[0];
      } else {
        siteFooterAddress.value = "";
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      //loading = false;
    });
};

watch(languageCode, () => {
  console.log("isLoggedIn ref changed, do something!");
  getLanguage();
  footerAddress();
});

onMounted(() => {
  getLanguage();
  footerAddress();
});

onActivated(() => {
  //getLanguage();
});
</script>
