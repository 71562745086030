<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>{{ translationText.text.orders }}</li>
    </ul>
    <div class="single-deta-box table-body-bg" data-aos="fade-up">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs" id="myTab">
            <li class="nav-item">
              <a href="#complex" class="nav-link active" data-bs-toggle="tab">{{
                translationText.text.orders
              }}</a>
            </li>
            <li class="nav-item">
              <a
                href="#prezerv"
                @click="getOrderByStatus('pending')"
                class="nav-link"
                data-bs-toggle="tab"
                >{{ translationText.text.openOrders }}</a
              >
            </li>
            <li class="nav-item">
              <a
                href="#viprox"
                @click="getOrderByStatus('cancel')"
                class="nav-link"
                data-bs-toggle="tab"
                >{{ translationText.text.cancelledOrders }}</a
              >
            </li>
            <li class="nav-item">
              <a
                href="#complex2"
                @click="getOrderAutoShip()"
                class="nav-link"
                data-bs-toggle="tab"
                >{{ translationText.text.autoShipOrders }}</a
              >
            </li>
            <!-- <li class="nav-item">
              <a
                href="#unevie"
                @click="getOrderByStatus('return')"
                class="nav-link"
                data-bs-toggle="tab"
                >Refunded Orders</a
              >
            </li> -->
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" id="complex">
              <div class="container mt-5">
                <div class="table-responsive">
                  <table class="table table-borderless main">
                    <thead>
                      <!-- <tr class="head">
                      <th scope="col" class="ml-2">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                        </div>
                      </th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col">Customer</th>
                      <th scope="col">Fullfillment</th>
                      <th scope="col">Total</th>
                      <th scope="col">Profit</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated</th>
                    </tr> -->
                    </thead>
                    <tbody>
                      <template v-for="(order, index) in orders">
                        <tr class="rounded bg-white">
                          <td class="order-color">
                            Order# {{ order.order_number }}
                          </td>

                          <td class="d-flex align-items-center">
                            <template
                              v-for="(orderProduct, index) in order.products"
                            >
                              <div>
                                <img
                                  :src="orderProduct.image_url"
                                  class="rounded-circle"
                                  width="25"
                                />
                                <span class="ml-2">{{
                                  orderProduct.name
                                }}</span>
                              </div>
                            </template>
                          </td>
                          <td>
                            {{ translationText.text.total }} ${{ order.total }}
                          </td>
                          <td>
                            <ul>
                              <li>
                                <router-link
                                  :to="{
                                    name: 'OrdersDetails',
                                    params: { id: order.id },
                                  }"
                                >
                                  {{ translationText.text.orderDetails }}
                                </router-link>
                              </li>

                              <!-- <li>
                                <a class="dropdown-item" href="#"
                                  >Print Receipts</a
                                >
                              </li> -->
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0)"
                                  @click="cancelOrdersFun(order.id)"
                                  >{{ translationText.text.return }}</a
                                >
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="prezerv">
              <div class="container mt-5">
                <div class="table-responsive">
                  <table class="table table-borderless main">
                    <thead></thead>
                    <tbody>
                      <template v-for="(order, index) in openOrders">
                        <tr class="rounded bg-white">
                          <td class="order-color">
                            Order# {{ order.order_number }}
                          </td>

                          <td class="d-flex align-items-center">
                            <template
                              v-for="(orderProduct, index) in order.products"
                            >
                              <div>
                                <img
                                  :src="orderProduct.image_url"
                                  class="rounded-circle"
                                  width="25"
                                />
                                <span class="ml-2">{{
                                  orderProduct.name
                                }}</span>
                              </div>
                            </template>
                          </td>
                          <td>
                            {{ translationText.text.total }} ${{ order.total }}
                          </td>
                          <td>
                            <ul>
                              <li>
                                <router-link
                                  :to="{
                                    name: 'OrdersDetails',
                                    params: { id: order.id },
                                  }"
                                >
                                  {{ translationText.text.orderDetails }}
                                </router-link>
                              </li>

                              <!-- <li>
                                <a class="dropdown-item" href="#"
                                  >Print Receipts</a
                                >
                              </li> -->
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0)"
                                  @click="cancelOrdersFun(order.id)"
                                  >{{ translationText.text.return }}</a
                                >
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="viprox">
              <div class="container mt-5">
                <div class="table-responsive">
                  <table class="table table-borderless main">
                    <thead></thead>
                    <tbody>
                      <template v-for="(order, index) in cancelOrders">
                        <tr class="rounded bg-white">
                          <td class="order-color">
                            Order# {{ order.order_number }}
                          </td>

                          <td class="d-flex align-items-center">
                            <template
                              v-for="(orderProduct, index) in order.products"
                            >
                              <div>
                                <img
                                  :src="orderProduct.image_url"
                                  class="rounded-circle"
                                  width="25"
                                />
                                <span class="ml-2">{{
                                  orderProduct.name
                                }}</span>
                              </div>
                            </template>
                          </td>
                          <td>
                            {{ translationText.text.total }} ${{ order.total }}
                          </td>
                          <td>
                            <ul>
                              <li>
                                <router-link
                                  :to="{
                                    name: 'OrdersDetails',
                                    params: { id: order.id },
                                  }"
                                >
                                  {{ translationText.text.orderDetails }}
                                </router-link>
                              </li>

                              <!-- <li>
                                <a class="dropdown-item" href="#"
                                  >Print Receipts</a
                                >
                              </li> -->
                              <!-- <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0)"
                                  @click="cancelOrdersFun(order.id)"
                                  >Return</a
                                >
                              </li> -->
                            </ul>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="complex2">
              <div class="container mt-5">
                <div class="table-responsive">
                  <table class="table table-borderless main">
                    <thead></thead>
                    <tbody>
                      <template v-for="(order, index) in autoShipOrders">
                        <tr class="rounded bg-white">
                          <td class="order-color">
                            Order# {{ order.order_number }}
                          </td>

                          <td class="d-flex align-items-center">
                            <template
                              v-for="(orderProduct, index) in order.products"
                            >
                              <div>
                                <img
                                  :src="orderProduct.image_url"
                                  class="rounded-circle"
                                  width="25"
                                />
                                <span class="ml-2">{{
                                  orderProduct.name
                                }}</span>
                              </div>
                            </template>
                          </td>
                          <td>
                            {{ translationText.text.total }} ${{ order.total }}
                          </td>
                          <td>
                            <ul>
                              <li>
                                <router-link
                                  :to="{
                                    name: 'OrdersDetails',
                                    params: { id: order.id },
                                  }"
                                >
                                  {{ translationText.text.orderDetails }}
                                </router-link>
                              </li>

                              <!-- <li>
                                <a class="dropdown-item" href="#"
                                  >Print Receipts</a
                                >
                              </li> -->
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0)"
                                  @click="cancelOrdersFun(order.id)"
                                  >{{ translationText.text.return }}</a
                                >
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- <div class="tab-pane fade" id="unevie">
              <div class="container mt-5">
                <div class="table-responsive">
                  <table class="table table-borderless main">
                    <thead></thead>
                    <tbody>
                      <template v-for="(order, index) in refundOrders">
                        <tr class="rounded bg-white">
                          <td class="order-color">
                            Order# {{ order.order_number }}
                          </td>

                          <td class="d-flex align-items-center">
                            <template
                              v-for="(orderProduct, index) in order.products"
                            >
                              <div>
                                <img
                                  :src="orderProduct.image_url"
                                  class="rounded-circle"
                                  width="25"
                                />
                                <span class="ml-2">{{ orderProduct.name }}</span>
                              </div>
                            </template>
                          </td>
                          <td>Total ${{ order.total }}</td>
                          <td>
                            <ul>
                              <li>
                                <router-link
                                  :to="{
                                    name: 'OrdersDetails',
                                    params: { id: order.id },
                                  }"
                                >
                                  Order Details
                                </router-link>
                              </li>

                              <li>
                                <a class="dropdown-item" href="#"
                                  >Print Receipts</a
                                >
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0)"
                                  @click="cancelOrdersFun(order.id)"
                                  >Return</a
                                >
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                <div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRouter } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

const router = useRouter();
const userLanguages = userLanguage();
const userLogin = useLoginStore();
const { languageCode } = storeToRefs(userLanguages);
const orders = ref([]);

const openOrders = ref([]);
const cancelOrders = ref([]);
const autoShipOrders = ref([]);
const refundOrders = ref([]);
const props = defineProps(["baseURL"]);
const treeConfig = ref({ nodeWidth: 120, nodeHeight: 80, levelHeight: 200 });
const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};

const cancelOrdersFun = (orderId) => {
  swal("You won't be able to revert this!", {
    buttons: {
      cancel: "Cancel",
      ok: {
        value: "ok",
      },
    },
  }).then((value) => {
    if (value == "ok") {
      userLanguages.setLoading(true);
      axios
        .get(props.baseURL + "order/cancel/" + orderId, {
          headers,
        })
        .then((response) => {
          console.log("Cancel Order ", response.data.message);
          userLanguages.setLoading(false);
          swal({
            position: "top-end",
            icon: "success",
            title: response.data.message,
            button: false,
            timer: 2500,
          });
        })
        .catch((error) => {
          userLanguages.setLoading(false);
          console.error("Error fetching data:", error);
        });
    }
  });
};
const getOrderAutoShip = () => {
  userLanguages.setLoading(true);
  axios
    .get(props.baseURL + "user/orders/autoship", {
      headers,
    })
    .then((response) => {
      console.log("Order Listing ", response.data.data);
      autoShipOrders.value = response.data.data;
      userLanguages.setLoading(false);
    })
    .catch((error) => {
      userLanguages.setLoading(false);
      console.error("Error fetching data:", error);
    });
};
const getOrderByStatus = (byStatus) => {
  console.log("get Order id", byStatus);
  userLanguages.setLoading(true);
  axios
    .get(props.baseURL + "user/orders/" + byStatus, {
      headers,
    })
    .then((response) => {
      console.log("Order Listing ", response.data.data);
      if (byStatus == "pending") {
        openOrders.value = response.data.data;
      }
      if (byStatus == "cancel") {
        cancelOrders.value = response.data.data;
      }

      if (byStatus == "return") {
        refundOrders.value = response.data.data;
      }

      if (byStatus == "autoship") {
        autoShipOrders.value = response.data.data;
      }
      userLanguages.setLoading(false);
      //orders.value = response.data.data;
    })
    .catch((error) => {
      userLanguages.setLoading(false);
      console.error("Error fetching data:", error);
    });
};

const getGenealogy = () => {
  userLanguages.setLoading(true);
  //const apiUrl = props.baseURL + "translateany";
  axios
    .get(props.baseURL + "user/orders", {
      headers,
    })
    .then((response) => {
      console.log("Order Listing ", response.data.data);
      orders.value = response.data.data;
      userLanguages.setLoading(false);
    })
    .catch((error) => {
      userLanguages.setLoading(false);
      console.error("Error fetching data:", error);
    });
};

const translationText = ref({
  text: {
    orders: "Orders",
    openOrders: "Open Orders",
    cancelledOrders: "Cancelled Orders",
    autoShipOrders: "AutoShip Orders",
    total: "Total",
    orderDetails: "Order Details",
    return: "Return",
  },
  language: "",
});
const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl = props.baseURL + "translateany";
  axios
    .post(apiUrl, translationText.value)
    .then((response) => {
      console.log("response trnasate :", response.data);
      translationText.value = response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

watch(languageCode, () => {
  console.log("ref sidebar language change");
  translatText();
});

onMounted(() => {
  translatText();
  getGenealogy();
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}
</style>
